<template>
  <b-card title="Laporan Bank Transfer">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group label="Kelompok Gaji" label-for="periode_date">
                  <validation-provider #default="{ errors }" name="periode_date" rules="required">
                    <b-form-input id="periode_date" v-model="dataParent.periode_date" type="text"
                      :state="errors.length > 0 ? false : null" placeholder="Kelompok Gaji" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Periode" label-for="periode_date">
                  <validation-provider #default="{ errors }" name="periode_date" rules="required">
                    <b-form-input id="periode_date" v-model="dataParent.periode_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Periode" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                    @click="GetAllData">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1"
                    @click="ExportExcel">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Bank" label-for="periode_date">
                  <validation-provider #default="{ errors }" name="periode_date" rules="required">
                    <b-form-input id="periode_date" v-model="dataParent.periode_date" type="text"
                      :state="errors.length > 0 ? false : null" placeholder="Bank" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Catatan" label-for="periode_date">
                  <validation-provider #default="{ errors }" name="periode_date" rules="required">
                    <b-form-input id="periode_date" v-model="dataParent.periode_date" type="text"
                      :state="errors.length > 0 ? false : null" placeholder="Catatan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <table class="table" id="recaptable">
      <thead>
        <tr>
          <th>No</th>
          <th>ID Pegawai</th>
          <th>NIP</th>
          <th>Nama Lengkap</th>
          <th>Pemilik Rekening</th>
          <th>Nomor Rekening</th>
          <th>Total Transfer</th>
          <th>Catatan</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import Table from '@/views/table/vue-good-table/GoodTable.vue'
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
//import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"



import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    $('#datatable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'NIP',
          field: 'employee_id',
        },
        {
          label: 'Nama Lengkap',
          field: 'name',
        },
        {
          label: 'L/P',
          field: 'gender',
        },
        {
          label: 'Aktif',
          field: 'is_active',
        },
        {
          label: 'Jabatan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Masa Kerja',
          field: 'working_period',
        },
        {
          label: 'Usia',
          field: 'age',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        periode_date: '',
      },
      DataProduct: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    // this.GetAllData()
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    async Filter() {
      try {
        var Period = document.getElementById('periode_date');
        const PeriodeDate = Period.value.substring(0, 7);
        console.log(PeriodeDate)
        //this.$router.push({ name: 'laporan-rekap-gaji-filter', params: { id: PeriodeDate } })
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    async GetAllData() {
      try {
        var Period = document.getElementById('periode_date');
        const PeriodeDate = Period.value.substring(0, 7);
        //const PeriodeDate = this.$route.params.id
        const response = await this.$http.get(`/payrollrecap?periode_date=${PeriodeDate}`)
        var DataRespone = response.data.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var DataDetail = DataRespone[i].details;
          var res = DataDetail.map(bill => parseInt(bill.nominal)).reduce((acc, nominal) => acc + nominal);
          var JsonData = {
            "no": number,
            "periode_date": DataRespone[i].periode_date,
            "component_name": DataRespone[i].component_name,
            "total": res
          }
          RecapTemp.push(JsonData);
        });
        this.DataProduct = this.RecapTemp;
        console.log(RecapTemp);
        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "Periode",
            "data": "periode_date"
          }, {
            "title": "Nama Komponen",
            "data": "component_name"
          }, {
            "title": "Total",
            "data": "total"
          }],
          "columnDefs": [
            {
              targets: 3,
              render: $.fn.dataTable.render.number(',', '.', 2, '')
            }
          ],
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          console.log(value);
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/employees/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
